<template>
  <admin-layout isLayout="ideas">
    <h1 style="margin: 20px 15px">FİKİRLER</h1>
    <div
      v-if="getDraftIdeaData.length > 0"
      style="margin: 50px 15px"
      class="ideas-table-wrapper"
    >
      <vue-good-table
        class="admin-table"
        :columns="columns"
        :rows="getDraftIdeaData"
        :line-numbers="true"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'action'">
            <button
              class="btn-innovation-radius bg-blue-2 px-4 py-2"
              data-toggle="modal"
              data-target="#modal-idea-detail"
              @click.stop="tableEdit(props.row)"
            >
              Düzenle
            </button>
            <button
              class="btn-innovation-radius bg-red-1 px-4 py-2"
              data-toggle="modal"
              data-target="#modal-idea-detail"
              @click.stop="deleteCardShowModal(props.row)"
            >
              Sil
            </button>
          </div>
          <div
            v-else-if="
              props.column.field == 'CreatedOnUtc' ||
              props.column.field == 'SuggestionDate'
            "
          >
            {{
              props.formattedRow[props.column.field] | newDateFormatterFilter
            }}
          </div>
          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div></template
        >
      </vue-good-table>

      <div class="row mt-4 d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <NotIdea v-else />
    <transition name="fade">
      <ShareIdeas
        v-if="isShareIdea"
        @close="isShareIdea = $event"
        :editNewestsData="editNewestsData"
        isIdea="main"
        :isDraft="true"
      />
    </transition>
    <transition name="fade">
      <CardDelete
        v-if="isCardDelete"
        @close="isCardDelete = $event"
        @delete="tableDelete"
        :isSuggestion="false"
      />
    </transition>
  </admin-layout>
</template>

<script>
import { mapGetters } from "vuex";

import { suggestionService, upload } from "../../services";
import { fileTypeCheck, newDateFormatter } from "../../helpers";

import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import AdminLayout from "../../layouts/AdminLayout.vue";
import NotIdea from "../../components/MyIdea/NotIdea.vue";
import ShareIdeas from "../../components/Modals/ShareIdeas.vue";
import CardDelete from "../../components/Main/Cards/Modals/CardDelete.vue";
export default {
  name: "DraftIdea",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      columns: [
        {
          label: "Fikir Tarihi",
          field: "SuggestionDate",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Sorun/Fırsat",
          field: "Problem",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir",
          field: "SuggestionDetail",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Değer Akımı",
          field: "ValueCurrentName",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },

        {
          label: "Statü",
          field: "SuggestionStatusName",
          sortable: false,
          thClass: "vgt-center-align",
        },
        {
          label: "",
          field: "action",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
      ],
      editNewestsData: null,
      isShareIdea: false,
      cardDeleteDataId: null,
      isCardDelete: false,
    };
  },
  components: {
    VueGoodTable,
    AdminLayout,
    NotIdea,
    ShareIdeas,
    CardDelete,
  },
  filters: {
    newDateFormatterFilter(value) {
      return newDateFormatter(value);
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getDraftIdeaData() {
      return this.$store.getters["draft/getDraftIdeaData"];
    },
    totalRecords() {
      return this.$store.getters["draft/getDraftIdeaTotalRecords"];
    },
  },
  async created() {
    this.getData();
  },
  methods: {
    async getData() {
      const body = {
        pageIndex: this.currentPage - 1,
        pageSize: this.perPage,
        suggestionStatuses: [11],
        createdUserId: this.user.Data.UserId,
        loginUserId: this.user.Data.UserId,
      };
      await this.$store.dispatch("draft/setDraftIdeaData", body);
    },
    async tableEdit(event) {
      this.$store.dispatch("loading/setIsLoading", true);
      this.editNewestsData = event;
      this.editNewestsData.DownloadFile = [];
      if (this.editNewestsData.FileIds.length > 0) {
        this.editNewestsData.FileIds.forEach(async (element) => {
          const res = await upload.fetchDownloadFile(element);
          this.editNewestsData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
            fileType: fileTypeCheck(res.Data.Extension),
          });
        });
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isShareIdea = true;
    },
    deleteCardShowModal(item) {
      this.cardDeleteDataId = item.Id;
      this.isCardDelete = true;
    },
    async tableDelete() {
      try {
        const res = await suggestionService.fetchDeleteSuggestion(
          this.cardDeleteDataId
        );
        if (res.Message == "Suggestion is deleted") {
          this.$toast.success("İşleminiz başarıyla tamamlandı.", {
            position: "top",
          });
          this.$store.dispatch("draft/deleteDraftIdeaData");
          const body = {
            pageIndex: 0,
            pageSize: 15,
            suggestionStatuses: [11],
            createdUserId: this.user.Data.UserId,
            loginUserId: this.user.Data.UserId,
          };
          this.$store.dispatch("draft/setDraftIdeaData", body);
        } else {
          this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
            position: "top",
          });
        }
      } catch (error) {
        this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
          position: "top",
        });
      }
    },
  },
};
</script>

<style></style>
